
import {defineComponent, onMounted} from 'vue'
import {useAccountStore} from '@/stores/account-store'
import {isLogined} from '@/utils/browser-utils'

export default defineComponent({
  components: {},
  setup() {
    const {
      migrationCode,
      migrationCodeError,
      migrationCodeLoaded,
      migrationCodeLoading,
      fetchMigrationCode,
    } = useAccountStore()

    onMounted(async () => {
      try {
        if (isLogined()) {
          await fetchMigrationCode()
        } else {
          migrationCodeLoaded.value = true
        }
      } catch (e) {
        // console.error(e)
      }
    })

    const env = process.env.NODE_ENV

    return {
      migrationCode,
      migrationCodeError,
      migrationCodeLoaded,
      migrationCodeLoading,
      env,
    }
  },
})
