import {reactive, toRefs} from 'vue'
import {accountService} from '@/services/api.v1/account-service'
import {SigninRequest} from '@/models/account'
import {ProviderIds} from '@/config/enum'

import {useSessionStore} from './session-store'
import {userService} from '@/services/api.v1/user-service'
import {UserDeleteRequest} from '@/models/user'
import {ApiOptions} from '@/services/api.v1/common/api-service'

type State = {
  userId: number
  migrationCode: string | undefined
  migrationCodeLoading: boolean
  migrationCodeLoaded: boolean
  migrationCodeError: boolean
}

const state = reactive<State>({
  userId: 99,
  migrationCode: undefined,
  migrationCodeLoading: false,
  migrationCodeLoaded: false,
  migrationCodeError: false,
})

export function useAccountStore() {
  const signinByLineToken = async (lineToken: string, options?: ApiOptions) => {
    const request: SigninRequest = {
      providerId: ProviderIds.Line,
      token: lineToken,
    }

    const res = await accountService.signin(request, options)
    const {setAuthInfo} = useSessionStore()
    await setAuthInfo(res)
    // サインイン直後にAPIを実行すると認証エラーになるケースがあるため少し待つ。
    // TypeORMもしくはExpress Middlewareでトークン検証のためのクエリをキャッシュしているためと思われる。
    await new Promise((resolve) =>
      setTimeout(() => {
        resolve('')
      }, 500),
    )
  }

  const deleteUser = async (reason: string) => {
    const request: UserDeleteRequest = {
      reason,
    }
    await userService.deleteUser(request)
  }

  const fetchMigrationCode = async () => {
    state.migrationCodeLoading = true
    state.migrationCodeError = false
    try {
      const res = await userService.fetchMigrationCode()
      state.migrationCode = res.code ?? undefined
      state.migrationCodeLoaded = true
    } catch (e) {
      state.migrationCodeError = true
    } finally {
      state.migrationCodeLoading = false
    }
  }

  return {
    signinByLineToken,
    deleteUser,
    fetchMigrationCode,
    ...toRefs(state),
  }
}

export type AccountStore = ReturnType<typeof useAccountStore>
