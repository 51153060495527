import {useSessionLiffStore} from '@/stores/session-liff-store'
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterScrollBehavior,
} from 'vue-router'
import SHome from '@/views/SHome.vue'
import SProfile from '@/views/profile/SProfile.vue'
import SSignin from '@/views/account/SSignin.vue'
import SNotice from '@/views/SNotice.vue'
import {isLogined} from '@/utils/browser-utils'
import {useAccountStore} from '@/stores/account-store'
import SPrivacy from '@/views/privacy/SPrivacy.vue'
import STerms from '@/views/terms/STerms.vue'
import SProfileDelete from '@/views/profile/SProfileDelete.vue'
import SProfileSns from '@/views/profile/sns/SProfileSns.vue'
import SProfileEditor from '@/views/profile/SProfileEditor.vue'
import SError from '@/views/SError.vue'
import SMaintenance from '@/views/SMaintenance.vue'
import SBrowseCategory from '@/views/browse/category/SBrowseCategory.vue'
import SBrowse from '@/views/browse/SBrowse.vue'
import SVoucherDetail from '@/views/voucher/SVoucherDetail.vue'
import SShop from '@/views/shop/SShop.vue'
import SReserve from '@/views/reserve/SReserve.vue'
import SProfilePaymentList from '@/views/profile/payment/SProfilePaymentList.vue'
import SProfileAddressList from '@/views/profile/address/SProfileAddressList.vue'
import SProfileSnsAuth from '@/views/profile/sns/auth/SProfileSnsAuth.vue'
import SCampaignDetail from '@/views/campaign/SCampaignDetail.vue'
import {useSessionStore} from '@/stores/session-store'
import SReserveReceipt from '@/views/reserve/receipt/SReserveReceipt.vue'
import SReservePayment from '@/views/reserve/payment/SReservePayment.vue'
import SFavorite from '@/views/favorite/SFavorite.vue'

declare module 'vue-router' {
  // eslint-disable-next-line no-unused-vars
  interface RouteMeta {
    title?: string
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: SHome,
  },
  {
    path: '/favorite',
    name: 'Favorite',
    component: SFavorite,
    meta: {
      title: 'お気に入り',
    },
  },
  {
    path: '/notification',
    name: 'Notification',
    component: SHome,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: SProfile,
    meta: {
      title: 'マイページ',
    },
  },
  {
    path: '/signin',
    name: 'Signin',
    component: SSignin,
    meta: {noAuth: true},
  },
  {
    path: '/notice',
    name: 'Notice',
    component: SNotice,
    meta: {noAuth: true},
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: SPrivacy,
    meta: {
      noAuth: true,
      title: 'プライバシーポリシー',
    },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: STerms,
    meta: {
      noAuth: true,
      title: '利用規約',
    },
  },
  {
    path: '/profile/delete',
    name: 'ProfileDelete',
    component: SProfileDelete,
    meta: {
      title: '退会',
    },
  },
  {
    path: '/profile/sns',
    name: 'ProfileSns',
    component: SProfileSns,
    // SProfileSnsではなく、SProfileSnsCellでタイトル変更。
  },
  {
    path: '/profile/edit',
    name: 'ProfileEditor',
    component: SProfileEditor,
    meta: {
      title: 'ユーザー情報',
    },
  },
  {
    path: '/browse/category/:categoryId',
    name: 'BrowseCategory',
    component: SBrowseCategory,
    // タイトル可変なので、SBrowseCategoryの中でタイトル変更。
  },
  {
    path: '/browse',
    name: 'Browse',
    component: SBrowse,
    meta: {
      title: '検索',
    },
  },
  {
    path: '/voucher/:voucherId',
    name: 'VoucherDetail',
    component: SVoucherDetail,
    // タイトル可変なので、SVoucherDetailの中でタイトル変更。
  },
  {
    path: '/shop/:shopId',
    name: 'Shop',
    component: SShop,
    // タイトル可変なので、SShopの中でタイトル変更。
  },
  {
    path: '/reserve',
    name: 'Reserve',
    component: SReserve,
    meta: {
      title: '優待管理',
    },
  },
  {
    path: '/reserve/:voucherReserveId/receipt',
    name: 'ReserveReceipt',
    component: SReserveReceipt,
    meta: {
      title: '注文詳細',
    },
  },
  {
    path: '/reserve/:voucherReserveId/payment',
    name: 'ReservePayment',
    component: SReservePayment,
    meta: {
      title: '注文内容確認',
    },
  },
  {
    path: '/profile/payment',
    name: 'Payment',
    component: SProfilePaymentList,
    meta: {
      title: '決済情報',
    },
  },
  {
    path: '/profile/address',
    name: 'Address',
    component: SProfileAddressList,
    meta: {
      title: '住所管理',
    },
  },
  {
    path: '/profile/sns/:providerId/auth',
    name: 'SnsAuth',
    component: SProfileSnsAuth,
  },
  {
    path: '/campaign/:campaignId',
    name: 'CampaignDetail',
    component: SCampaignDetail,
  },
  {
    path: '/error',
    name: 'Error',
    component: SError,
    meta: {noAuth: true},
  },
  {
    path: '/maintenance',
    name: '',
    component: SMaintenance,
    meta: {noAuth: true},
  },
]

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  }
  return {top: 0}
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
})

const {getLineIdToken} = useSessionLiffStore()
const {setIsLoaging} = useSessionStore()
const {signinByLineToken} = useAccountStore()

router.beforeEach(async (to, from, next) => {
  document.title = to.meta?.title ?? 'SHOW'

  if (to.path === '/notice') {
    if (isLogined()) {
      next()
      return
    }

    try {
      setIsLoaging(true)
      const token = await getLineIdToken()
      await signinByLineToken(token, {
        preventShowApplicationError: true,
      })
    } catch (e) {
      // エラーが起きてもnoticeに飛ばす
    } finally {
      setIsLoaging(false)
    }
    next()
    return
  }

  next({path: '/notice'})
})

export default router
